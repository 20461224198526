
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import tasqsListModule from '@/store/modules/tasqsListModule';
import tasqProductionDataChartModule from '@/store/modules/tasqProductionDataChartModule';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import assetsModule from '@/store/modules/assetsModule';
import tasqSignalsModule from '@/store/modules/tasqSignalsModule';
import { HISTORY_SEARCH_UPDATED_EVENT } from '@/lib/constants';

import { getConfigEnv, sleep } from '@/utils/helpers';
import workflowModule from '@/store/modules/workflowModule';
import {
  lightningChart,
  //   OnScreenMenuButtonType,
  //   OnScreenMenuButtonShape,
  UIElementBuilders,
  UIOrigins,
  //   ImageFill,
  //   ImageFitMode,
  Themes,
  ColorRGBA,
  //   ColorHEX,
  SolidFill,
  //   PointShape,
  SolidLine,
  AxisTickStrategies,
  //   LegendBoxBuilders,
  //   AutoCursorModes,
  //   LegendBox,
  //   MouseClickEventType,
  //   UILUTCheckBox,
  emptyLine,
  //   emptyFill,
  ColorCSS,
  customTheme,
  //   UIVisibilityModes,
  //   AxisScrollStrategies,
} from '@arction/lcjs';
import gptModule from '@/store/modules/gptModule';
// // Extract required parts from XYData Generator.
// import {
//   createProgressiveTraceGenerator,
//   createOHLCGenerator,
//   createProgressiveRandomGenerator,
// } from '@arction/xydata';

@Component({})
export default class TasqLightningProductionChart extends Vue {
  @Prop({ type: Boolean, required: false, default: false }) isFullScreen?: boolean;

  chart: any = null;

  chartId: any = null;

  chartsLoading = false;

  localWellHistories: any = [];

  axisY;

  axisY2;

  defaultAxis = 'axisY2'

  minY = 0;

  maxY;

  dateOrigin;

  chartSeries: any = [];

  points = [];

  chartHistoryButtons: any = [];

  created() {
    this.$eventBus.$on(HISTORY_SEARCH_UPDATED_EVENT, this.handleHistorySearchUpdatedEvent);
  }

  handleHistorySearchUpdatedEvent(filteredWellHistoryEvents) {
    this.addHistoryToChart(
      Boolean(filteredWellHistoryEvents && filteredWellHistoryEvents.length),
      filteredWellHistoryEvents
    );
  }

  get isHistorySectionOpen() {
    return tasqsListModule.isHistorySectionOpen;
  }

  reloadChartOnScroll() {
    this.chart.engine.layout();
  }
  refresh() {
    this.chart.setTitle(' ');
    this.chart.setTitle('');
  }

  didSelectResetChart() {
    this.chart.getDefaultAxisX().release();
    this.chart.getDefaultAxisY().release();
    this.axisY.release();
    this.axisY2.release();
    if (this.maxY != undefined && this.maxY != -99999999) {
      this[this.defaultAxis].setInterval(this.minY, this.maxY, false, true);
    }
  }

  async initializePage(index, signal) {
    for (let x = 0; x < this.chartSeries.length; x++) {
      const check_signal = signal.replace('_', ' ');
      if (this.chartSeries[x].Uc.toLowerCase() == check_signal.toLowerCase()) {
        if (this.chartSeries[x].isDisposed()) {
          this.chartSeries[x].restore();
        } else {
          this.chartSeries[x].dispose();
        }
      }
    }
  }

  get wellHistoryEvents() {
    return workflowModule.wellHistoryEvents;
  }


  get gptLastActionEvents(){
    return gptModule.lastActions;
  }

  addHistoryToChart(showEvent, filteredWellHistoryEvents: any = []) {
    this.clearChartHistoryButtons();

    if (!showEvent) {
      return;
    }

    let wellHistoryEvents = filteredWellHistoryEvents;

    let gptLastActionEvents = this.gptLastActionEvents;
    if (!wellHistoryEvents.length) {
      wellHistoryEvents = this.wellHistoryEvents;
    }

    const axisX = this.chart.getDefaultAxisX();
    // for (var x = 0; x < wellHistoryEvents.length; x++) {
    //   if (wellHistoryEvents.hideEvent == true) {
    //     continue;
    //   }
    //   const timeObj = new Date(wellHistoryEvents[x].time);
    //   const diff = timeObj.getTime() - this.dateOrigin.getTime() - 172800000;
    //   const diff_in_min = diff / 60000;

    //   const fromTime = new Date();
    //   const toTime = new Date();
    //   toTime.setDate(toTime.getDate() - 1);
    //   fromTime.setDate(fromTime.getDate() - tasqsListModule.signalChartTime);

    //   // const fromTime = DateTime.utc().minus({ days: 365 })
    //   if (fromTime > timeObj) {
    //     continue;
    //   }

    //   const yInterval = this.chart.getDefaultAxisY().getInterval();
    //   const buttonReset = this.chart
    //     .addUIElement(UIElementBuilders.TextBox, { x: axisX, y: this.axisY })
    //     .setText('E')
    //     .setPosition({
    //       x: diff_in_min * (1 * 1000 * 60),
    //       y: yInterval.end - yInterval.end * 0.06,
    //     })
    //     .setOrigin(UIOrigins.LeftBottom)
    //     .setMargin({ left: 4, bottom: 4 });
    //   buttonReset.historyDetails = wellHistoryEvents[x];
    //   buttonReset.onMouseClick((obj, event) => {
    //     this.$emit('show-event-comment-popup', obj.historyDetails);
    //   });

    //   this.chartHistoryButtons.push(buttonReset);
    // }


    console.log(gptLastActionEvents);
    for (var x = 0; x < gptLastActionEvents.length; x++) {

      const timeObj = new Date(gptLastActionEvents[x].date);
      const diff = timeObj.getTime() - this.dateOrigin.getTime() - 172800000;
      const diff_in_min = diff / 60000;

      const fromTime = new Date();
      const toTime = new Date();
      toTime.setDate(toTime.getDate() - 1);
      fromTime.setDate(fromTime.getDate() - tasqsListModule.signalChartTime);

      // const fromTime = DateTime.utc().minus({ days: 365 })
      if (fromTime > timeObj) {
        continue;
      }

      const yInterval = this.chart.getDefaultAxisY().getInterval();
      const buttonReset = this.chart
        .addUIElement(UIElementBuilders.TextBox, { x: axisX, y: this.axisY })
        .setText('E')
        .setPosition({
          x: diff_in_min * (1 * 1000 * 60),
          y: yInterval.end - yInterval.end * 0.06,
        })
        .setOrigin(UIOrigins.LeftBottom)
        .setMargin({ left: 4, bottom: 4 })
       ;

        // if(gptLastActionEvents[x].active){
        //   buttonReset.setTextFillStyle(new SolidFill({ color: ColorRGBA(0, 255, 255) }))
        // }

      buttonReset.historyDetails = gptLastActionEvents[x];
      buttonReset.historyDetails['isGPT'] =  true
      buttonReset.onMouseClick((obj, event) => {
        this.$emit('show-event-comment-popup', obj.historyDetails);
      });

      this.chartHistoryButtons.push(buttonReset);
    }
  }

  clearChartHistoryButtons() {
    for (let x = 0; x < this.chartHistoryButtons.length; x++) {
      if (this.chartHistoryButtons[x]) {
        this.chartHistoryButtons[x].dispose();
      }
    }
  }

  get showWellEvents() {
    return tasqProductionDataChartModule.showWellEvents;
  }

  get isEditing() {
    return tasqsListModule.isEditing;
  }

  get activeTasq() {
    if (this.isEditing || tasqsListModule.checkedTasqs.length) {
      if (!tasqsListModule.activeTasq) {
        tasqsListModule.setActiveTasq(tasqsListModule.checkedTasqs[0]);
      }
      return tasqsListModule.activeTasq as TasqJob;
    }
    if (this.$route.query.type == 'id' && this.$route.params.id != null) {
      assetsModule.setActiveProducingTasq('');
      return tasqsListModule.activeTasq;
    }
    if (
      tasqsListModule.activeTasq != null &&
      this.$route.params.id != null &&
      this.$route.query.type == 'producing' &&
      tasqsListModule.activeTasq.level.toLowerCase() == 'pad'
    ) {
      return tasqsListModule.activeTasq;
    }
    if (assetsModule.activeTasq == undefined) {
      return tasqsListModule.activeTasq;
    }
    return assetsModule.activeTasq;
  }

  get tasqListLevel() {
    if (
      tasqsListModule.tasqListLevel.toLowerCase() == 'well' ||
      (this.activeTasq &&
        tasqsListModule.tasqListLevel.toLowerCase() !== 'wellview' &&
        this.activeTasq.level.toLowerCase() == 'well')
    ) {
      return 'Well';
    }
    return tasqsListModule.tasqListLevel.toLowerCase();
    //   if (this.activeTasq == null) {
    //    return 'WELL';
    //   }
    //   return this.activeTasq?.level;
  }

  get signalDescriptions(): any {
    return tasqSignalsModule.signalDescriptions;
  }

  get currentSignals(): any {
    // if (this.chartsLoading) {
    //   return [];
    // }
    const results: any[] = [];
    for (let x = 0; x < this.signalDescriptions.length; x++) {
      // @ts-ignore
      if (this.tasqListLevel.toLowerCase() == 'pad') {
        if (!(this.signalDescriptions[x].indexOf('Compressor') !== -1)) {
          continue;
        }
      }
      for (let y = 0; y < tasqSignalsModule.currentSignals.length; y++) {
        if (tasqSignalsModule.currentSignals[y].name == this.signalDescriptions[x]) {
          results.push(tasqSignalsModule.currentSignals[y]);
        }
      }
    }
    // signalDescriptions
    return results;
  }

  storedLinesSeries: any = [];

  setViewNicely = (areaRange, stockAxisY) => {
    const yBoundsStock = {
      min: areaRange.getYMin(),
      max: areaRange.getYMax(),
      range: areaRange.getYMax() - areaRange.getYMin(),
    };
    // Set Y axis intervals so that series don't overlap and volume is under stocks.
    stockAxisY.setInterval(yBoundsStock.min - yBoundsStock.range * 0.33, yBoundsStock.max);
  };

  get productionDataDict(): any {
    return tasqProductionDataChartModule.productionDataDict;
  }

  isSignalSelectedProd(signal_name) {
    return tasqProductionDataChartModule.selectedSignals.indexOf(signal_name) > -1;
  }


  get productionType() {
    return getConfigEnv('PRODUCTION_TYPE');
  }

  get enableTestVolumes() {
    return getConfigEnv('ENABLE_TEST_VOLUMES');
  }

  get legendSignalsProd(): any {
    const legendSignals: any = [];

    for (const [key, value] of Object.entries(this.productionDataDict)) {
      if (
        key == 'date' ||
        value == null ||
        value == undefined ||
        key == 'nodeid'
      ) {
        continue;
      }

      const allowedKeys = ['water_rate', 'gas_rate', 'oil_rate'];
      if (this.productionType === 'boe') {
        allowedKeys.push('boe_rate');
      }
      if (this.enableTestVolumes) {
        allowedKeys.push('water_rate_fc');
        allowedKeys.push('water_rate_source');
        allowedKeys.push('oil_rate_fc');
        allowedKeys.push('oil_rate_source');
        allowedKeys.push('gas_rate_fc');
        allowedKeys.push('gas_rate_source');
      }

      if (!allowedKeys.includes(key)) {
        continue;
      }
      let color = '';
      if (key == 'water_rate') {
        color = '#0077f0';
      } else if (key == 'gas_rate') {
        color = '#f55d8b';
      } else if (key == 'oil_rate') {
        color = '#2de6c1';
      } else if (key == 'boe_rate') {
        color = '#FFD977';
      } else if (key == 'water_rate_fc') {
        color = '#66B2FF'; // RGB(102, 178, 255)
      } else if (key == 'water_rate_source') {
        color = '#004EA2'; // RGB(0, 78, 162)
      } else if (key === 'oil_rate_fc') {
        color = '#4BFFD2'; // RGB(75, 255, 210)
      } else if (key === 'oil_rate_source') {
        color = '#1EB496'; // RGB(30, 180, 150)
      } else if (key === 'gas_rate_fc') {
        color = '#FF6993'; // RGB(255, 105, 147)
      } else if (key === 'gas_rate_source') {
        color = '#CD4E74'; // RGB(205, 78, 116)
      }

      legendSignals.push({
        name: key,
        selected: this.isSignalSelectedProd(key),
        color,
        type: 'PRODUCTION',
      });
    }
    return legendSignals;
  }

  getSignalIndex(signal_name) {
    for (let x = 0; x < this.chartSeries.length; x++) {
      const check_signal = signal_name.replace('_', ' ');
      if (this.chartSeries[x].Uc.toLowerCase() == check_signal.toLowerCase()) {
        return x;
      }
    }
    return -1;
  }



  async updateChartToMatchLegend() {
	  for (let a = 0; a < this.legendSignalsProd.length; a++) {
	    if (this.chartSeries[this.getSignalIndex(this.legendSignalsProd[a].name)] && tasqProductionDataChartModule.selectedSignals.indexOf(this.legendSignalsProd[a].name) > -1) {
	      this.chartSeries[this.getSignalIndex(this.legendSignalsProd[a].name)].restore();
	    } else if( this.chartSeries[this.getSignalIndex(this.legendSignalsProd[a].name)]) {
	      this.chartSeries[this.getSignalIndex(this.legendSignalsProd[a].name)].dispose();
	    }
	  }
	}

  setupForTargets(targetType) {
    let targetColorCode = ColorRGBA(245, 93, 139, 80);
    if (targetType === 'oil') {
      this.axisY.setTitle('Oil Rate & Water Rate');
      this.axisY2.setTitle('Gas Rate');
      targetColorCode = ColorRGBA(45, 230, 193, 80);
    } else if (targetType === 'gas') {
      this.axisY.setTitle('Gas Rate');
      this.axisY2.setTitle('Oil Rate & Water Rate');
    } else if (targetType === 'boe') {
      this.axisY.setTitle('Gas Rate');
      targetColorCode = ColorRGBA(255, 217, 119);
      this.axisY2.setTitle('Boe Rate & Water Rate');
    }

    this.maxY = -99999999;
    this.minY = 0;

    const prodDataFrequency = 1000 * 60 * 60 * 24;
    for (const [key, value] of tasqProductionDataChartModule.productionDataDictKeyValue) {
      if (key == 'date' || value == null || value == undefined || key == 'nodeid') {
        continue;
      }

      var day_count = 0;
      var check_date = new Date(this.dateOrigin.getTime());
      const signal_data: any = [];
      while (check_date < new Date(tasqProductionDataChartModule.productionDataDict.date[0])) {
        // @ts-ignore
        signal_data.push({
          // @ts-ignore
          x: day_count * 24 * 60 * 60 * 1000,
          // @ts-ignore
          y: 0,
        });

        check_date.setDate(check_date.getDate() + 1);
        day_count += 1;
      }

      for (let signal_y = 0; signal_y < tasqProductionDataChartModule.productionDataDict[key].length; signal_y++) {
        let val = parseFloat(tasqProductionDataChartModule.productionDataDict[key][signal_y]);
        if (isNaN(val)) {
          val = 0;
        }

        if (((key.includes(targetType) || targetType === 'oil' && key.includes('water_')) && key !== 'boe_rate') || (targetType === 'boe' && key === 'gas_rate')) {
        if (val > this.maxY) {
          this.maxY = val;
        }
        if (val < this.minY) {
          this.minY = val;
        }

      }
        // @ts-ignore
        signal_data.push({
          // @ts-ignore
          x: (signal_y + day_count) * prodDataFrequency,
          // @ts-ignore
          y: val,
        });
      }

      signal_data.push({
        // @ts-ignore
        x: tasqsListModule.signalChartTime * (1000 * 60 * 5 * 12 * 24),
        // @ts-ignore
        y: undefined,
      });

      let axis = this.axisY2;

      if (((key.includes(targetType) || targetType === 'oil' && key.includes('water_')) && key !== 'boe_rate') || (targetType === 'boe' && key === 'gas_rate')) {
        axis = this.axisY;
        this.defaultAxis = 'axisY'
        axis.setInterval(this.minY, this.maxY, false, true);
       
      }
    



    
      // Add line series to the chart
      const lineSeriesSignals = this.chart.addLineSeries({
        yAxis: axis,
      });

      if (key.includes(targetType)) {
        lineSeriesSignals.setStrokeStyle((style) => style.setThickness(2));
      }

      let signalColor: any = ColorRGBA(245, 93, 139);
      if (key == 'oil_rate') {
        signalColor = ColorRGBA(45, 230, 193);
      }
      if (key == 'water_rate') {
        signalColor = ColorRGBA(0, 118, 255);
      }
      if (key == 'boe_rate') {
        signalColor = ColorRGBA(255, 217, 119);
      }

      if (key == 'water_rate_fc') {
        signalColor = ColorRGBA(102, 178, 255);
      }

      if (key == 'water_rate_source') {
        signalColor = ColorRGBA(0, 78, 162)
      }

      if(key === 'oil_rate_fc'){
        signalColor = ColorRGBA(75, 255, 210)
      }

      if(key === 'oil_rate_source'){
        signalColor = ColorRGBA(30, 180, 150)
      }

      if(key === 'gas_rate_fc'){
        signalColor = ColorRGBA(255, 105, 147)
      }

      if(key === 'gas_rate_source'){
        signalColor = ColorRGBA(205, 78, 116)
      }

      lineSeriesSignals.setStrokeStyle(
        new SolidLine({
          thickness: 2,
          fillStyle: new SolidFill({ color: signalColor }),
        })
      );
      lineSeriesSignals.setName(key.replace('_', ' '));
      // Add data points to the line series
      lineSeriesSignals.add(signal_data);

      // legendProd.add(lineSeriesSignals)
      this.chartSeries.push(lineSeriesSignals);
      if (!this.isSignalSelectedProd(key)) {
        lineSeriesSignals.dispose();
      }
    }

    if (!tasqProductionDataChartModule.productionData.data[0]) {
      return;
    }
    // Add series.
    const targetAxisY = targetType === 'boe' ? this.axisY2 : this.axisY;
    const areaRange = this.chart.addAreaRangeSeries({ yAxis: targetAxisY }).setName('Target band');

    // var diff = timeObj.getTime() - this.dateOrigin.getTime();
    // var diff_in_min = (diff / 60000);

    // var fromTime = new Date();
    // var toTime = new Date();
    // toTime.setDate(toTime.getDate()-1);
    // fromTime.setDate(fromTime.getDate()-365);

    var day_count = 0;
    var check_date = new Date(this.dateOrigin.getTime());
    const new_signal_data: any = [];

    if (tasqProductionDataChartModule.productionData.time) {
      while (check_date < new Date(tasqProductionDataChartModule.productionData.upperDataTime[0])) {
        areaRange.add({
          position: day_count * 24 * 60 * 60 * 1000,
          high: 0,
          low: 0,
        });

        // @ts-ignore
        new_signal_data.push({
          // @ts-ignore
          x: day_count * 24 * 60 * 60 * 1000,
          // @ts-ignore
          y: 0,
        });

        check_date.setDate(check_date.getDate() + 1);
        day_count += 1;
      }
    }

    if (tasqProductionDataChartModule.productionData.data[0]) {
      for (var d = 0; d < tasqProductionDataChartModule.productionData.data[0].length; d++) {
        if (parseFloat(tasqProductionDataChartModule.productionData.data[0][d]) > this.maxY) {
          this.maxY = parseFloat(tasqProductionDataChartModule.productionData.data[0][d]);
        }

        areaRange.add({
          position: (d + day_count) * 24 * 60 * 60 * 1000,
          high: parseFloat(tasqProductionDataChartModule.productionData.data[0][d]),
          low: parseFloat(tasqProductionDataChartModule.productionData.data[2][d]),
        });
      }
    }
    this.setViewNicely(areaRange, targetAxisY);

    for (var d = 0; d < tasqProductionDataChartModule.productionData.data[1].length; d++) {
      // @ts-ignore
      new_signal_data.push({
        // @ts-ignore
        x: (d + day_count) * prodDataFrequency,
        // @ts-ignore
        y: parseFloat(tasqProductionDataChartModule.productionData.data[1][d]),
      });
      // Add line series to the chart
    }
    const lineSeriesSignalsProd = this.chart.addLineSeries({
      yAxis: targetAxisY,
    });

    lineSeriesSignalsProd.setStrokeStyle(
      new SolidLine({
        thickness: 2,
        fillStyle: new SolidFill({ color: ColorRGBA(170, 170, 170, 100) }),
      })
    );

    // Set stroke style of the line
    lineSeriesSignalsProd.setStrokeStyle((style) => style.setThickness(2));
    lineSeriesSignalsProd.setName('Target');
    // Add data points to the line series
    lineSeriesSignalsProd.add(new_signal_data);
    // legendProd.add(lineSeriesSignalsProd)
    areaRange.setLowStrokeStyle(
      new SolidLine({
        thickness: 1,
        fillStyle: new SolidFill({ color: targetColorCode }),
      })
    );
    areaRange.setLowStrokeStyleHighlight(
      new SolidLine({
        thickness: 1,
        fillStyle: new SolidFill({ color: targetColorCode }),
      })
    );
    areaRange.setHighStrokeStyle(
      new SolidLine({
        thickness: 1,
        fillStyle: new SolidFill({ color: targetColorCode }),
      })
    );
    areaRange.setHighStrokeStyleHighlight(
      new SolidLine({
        thickness: 1,
        fillStyle: new SolidFill({ color: targetColorCode }),
      })
    );
    areaRange.setHighStrokeStyle(
      new SolidLine({
        thickness: 1,
        fillStyle: new SolidFill({ color: targetColorCode }),
      })
    );
    areaRange.setHighStrokeStyleHighlight(
      new SolidLine({
        thickness: 1,
        fillStyle: new SolidFill({ color: targetColorCode }),
      })
    );

    areaRange.setHighFillStyle(
      new SolidFill({
        color: targetColorCode.setA(80),
      })
    );
    areaRange.setLowFillStyle(
      new SolidFill({
        color: targetColorCode.setA(80),
      })
    );
  }

  createChart() {
    const themeTextFillStyle = new SolidFill({ color: ColorCSS('#ffffff') });
    const themeAxisFillStyle = new SolidFill({ color: ColorCSS('#ffffff') });
    const themeMajorTickFillStyle = new SolidFill({ color: ColorCSS('#ffffff') });
    const themeMinorTickFillStyle = new SolidFill({ color: ColorCSS('#ffffff') });
    const themeMajorGridlineFillStyle = new SolidFill({ color: ColorCSS('#00000032') });
    const themeMinorGridlineFillStyle = new SolidFill({ color: ColorCSS('#00000014') });
    const themeUiBackgroundFillStyle = new SolidFill({
      color: ColorCSS('rgba(120,120,120,0.5)'),
    }); // Legend color
    const themeUiBackgroundBorderFillStyle = new SolidFill({
      color: ColorCSS('#2b2b2b'),
    });
    const themeCursorGridlineFillStyle = new SolidFill({ color: ColorCSS('#ffffff') });
    // darkMagenta
    const myTheme = customTheme(Themes.cyberSpace, {
      lcjsBackgroundFillStyle: new SolidFill({ color: ColorCSS('#00000000') }),
      panelBackgroundFillStyle: new SolidFill({ color: ColorCSS('#00000000') }),
      seriesBackgroundFillStyle: new SolidFill({ color: ColorCSS('#00000000') }),
      chartTitleFillStyle: themeTextFillStyle,
      axisTitleFillStyle: themeTextFillStyle,
      axisStyle: new SolidLine({ thickness: 2, fillStyle: themeAxisFillStyle }),
      numericTickStrategy: Themes.lightNew.numericTickStrategy
        .setMajorTickStyle((majorTicks) =>
          majorTicks
            .setLabelFillStyle(themeTextFillStyle)
            .setTickStyle(new SolidLine({ thickness: 1, fillStyle: themeMajorTickFillStyle }))
            .setGridStrokeStyle(new SolidLine({ thickness: 1, fillStyle: themeMajorGridlineFillStyle }))
        )
        .setMinorTickStyle((minorTicks) =>
          minorTicks
            // @ts-ignore
            .setLabelFillStyle(themeTextFillStyle)
            .setTickStyle(new SolidLine({ thickness: 1, fillStyle: themeMinorTickFillStyle }))
            .setGridStrokeStyle(new SolidLine({ thickness: 1, fillStyle: themeMinorGridlineFillStyle }))
        ),
      // seriesFillStyle: (i) => themeDataSeriesFillStyles[i % themeDataSeriesFillStyles.length],
      // seriesStrokeStyle: (i) => new SolidLine({ thickness: 2, fillStyle: themeDataSeriesFillStyles[i % themeDataSeriesFillStyles.length] }),
      uiBackgroundFillStyle: themeUiBackgroundFillStyle,
      uiBackgroundStrokeStyle: new SolidLine({
        thickness: 1,
        fillStyle: themeUiBackgroundBorderFillStyle,
      }),
      uiTextFillStyle: themeTextFillStyle,
      resultTableFillStyle: themeUiBackgroundFillStyle,
      resultTableStrokeStyle: new SolidLine({
        thickness: 1,
        fillStyle: themeUiBackgroundBorderFillStyle,
      }),
      resultTableTextFillStyle: themeTextFillStyle,
      customTickGridStrokeStyle: new SolidLine({
        thickness: 1,
        fillStyle: themeCursorGridlineFillStyle,
      }),
      uiPointableTextBoxFillStyle: themeUiBackgroundFillStyle,
      uiPointableTextBoxStrokeStyle: new SolidLine({
        thickness: 1,
        fillStyle: themeUiBackgroundBorderFillStyle,
      }),
      uiPointableTextBoxTextFillStyle: themeTextFillStyle,
      pointMarkerFillStyle: new SolidFill({ color: ColorCSS('#ffffff') }),
      chartXYZoomingRectangleFillStyle: new SolidFill({ color: ColorCSS('#ffffff16') }),
      chartXYZoomingRectangleStrokeStyle: new SolidLine({
        thickness: 1,
        fillStyle: new SolidFill({ color: ColorCSS('#4f4f4f') }),
      }),
      chartXYFittingRectangleFillStyle: new SolidFill({ color: ColorCSS('#ffffff16') }),
      chartXYFittingRectangleStrokeStyle: new SolidLine({
        thickness: 1,
        fillStyle: new SolidFill({ color: ColorCSS('#4f4f4f') }),
      }),
    });

    const license = `0001-ff5ad4e054f80f2dd0977e5194ea2c7804dcae2d8fc19d352bb53ce081d1e1c8cc21be14a8b691660e1133da1d4d73813c2b4d760ddc385f32cd859fc3f6b7ed664894c62e4fa717fea588bb829429c8f7f9218a4f1f6684ca663999e246-2acb5316c96ce6435be3182ea4ee94db-30450220319f968bebc89be5d0ac8cb5b1903e4e023a07525c1b84d8aed5136bb0667a82022100f3ad7a24bde5f7427f10f3c1367aff4708fad5507d937da7e75220d5e29578d9`;
    if (location.href.includes('tasq.io')) {
      this.chart = lightningChart(license)
        .ChartXY({ container: `${this.chartId}`, theme: myTheme })
        .setTitle('');
    } else {
      this.chart = lightningChart()
        .ChartXY({ container: `${this.chartId}`, theme: myTheme })
        .setTitle('');
    }

    //   this.chart = lightningChart(license).ChartXY({ container: `${this.chartId}`, theme: myTheme }).setTitle('');
    // .setPadding({ top: 50 })
    this.chart.setAnimationsEnabled(false);
    this.chart.setMouseInteractionWheelZoom(false);

    //   setTimeout(() => {
    //     this.$nextTick(() => {
    //       this.didSelectResetChart();
    //     });
    //   }, 2000);

     // var offset = new Date().getTimezoneOffset();
	  const d = new Date(tasqsListModule.chartFromTime);
	  // d.setDate(d.getDate()-tasqsListModule.signalChartTime);
	  // d.setMinutes(d.getMinutes() - offset);
	  this.dateOrigin = d;
	  this.chart.getDefaultAxisX().setTickStrategy(AxisTickStrategies.DateTime, (tickStrategy) => tickStrategy.setDateOrigin(this.dateOrigin));
  }

  async mounted() {
    // Chart can only be created when the component has mounted the DOM because
    // the chart needs the element with specified containerId to exist in the DOM
    this.createChart();

    if (this.isFullScreen) {
      this.updateChartToMatchLegend();
    }

    const fromTime = new Date();
    fromTime.setDate(fromTime.getDate() -  280);


    console.log(fromTime);

    this.dateOrigin = fromTime;
    // Modify the default X Axis to use DateTime TickStrategy, and set the origin for the DateTime Axis.
    this.chart
      .getDefaultAxisX()
      .setTickStrategy(AxisTickStrategies.DateTime, (tickStrategy) => tickStrategy.setDateOrigin(this.dateOrigin));
    // const legendProd = this.chart.addLegendBox()
    // Dispose example UI elements automatically if they take too much space. This is to avoid bad UI on mobile / etc. devices.
    // .setAutoDispose({
    //  type: 'max-width',
    //  maxWidth: 0.30,
    // })
    this.axisY2 = this.chart.addAxisY({
      opposite: true,
    });
    this.axisY = this.chart
      .getDefaultAxisY()
      // Hide tick grid-lines from second Y axis.
      .setTickStrategy(AxisTickStrategies.Numeric, (ticks) =>
        ticks
          .setMinorTickStyle((minor) => minor.setGridStrokeStyle(emptyLine))
          .setMajorTickStyle((major) => major.setGridStrokeStyle(emptyLine))
      );

    const productionType = getConfigEnv('PRODUCTION_TYPE');
    if (productionType) {
      this.setupForTargets(productionType);
    }

    if (this.maxY != undefined && this.maxY != -99999999) {
      this[this.defaultAxis].setInterval(this.minY, this.maxY, false, true);
    }
    setTimeout(() => {
      if (this.maxY != undefined && this.maxY != -99999999) {
        this[this.defaultAxis].setInterval(this.minY, this.maxY, false, true);
      }
    }, 1000);

    this.addHistoryToChart(this.showWellEvents);
  }

  beforeMount() {
    // Generate random ID to us as the containerId for the chart and the target div id
    this.chartId = Math.trunc(Math.random() * 1000000);
  }

  beforeDestroy() {
    this.chart.dispose();
    this.$eventBus.$off(HISTORY_SEARCH_UPDATED_EVENT, this.handleHistorySearchUpdatedEvent);
    this.chart = null;
  }

  @Watch('wellHistoryEvents')
  async onwellHistoryEventsChange(newValue, oldValue) {
    if (!this.isHistorySectionOpen) {
      this.addHistoryToChart(this.showWellEvents);
    } else {
      tasqProductionDataChartModule.setShowWellEvents(false);
      await sleep(100);
      tasqProductionDataChartModule.setShowWellEvents(true);
    }
  }


  @Watch('gptLastActionEvents')
  async onGptLastActionEventsChange(newValue, oldValue) {
    if (!this.isHistorySectionOpen) {
      this.addHistoryToChart(this.showWellEvents);
    } else {
      tasqProductionDataChartModule.setShowWellEvents(false);
      await sleep(100);
      tasqProductionDataChartModule.setShowWellEvents(true);
    }
  }
}
